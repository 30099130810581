import { render } from "./CatalogEntryDetailsPanel.vue?vue&type=template&id=cf910956"
import script from "./CatalogEntryDetailsPanel.vue?vue&type=script&lang=ts"
export * from "./CatalogEntryDetailsPanel.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "cf910956"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('cf910956', script)) {
    api.reload('cf910956', script)
  }
  
  module.hot.accept("./CatalogEntryDetailsPanel.vue?vue&type=template&id=cf910956", () => {
    api.rerender('cf910956', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/aceEditor/autocomplete/details/CatalogEntryDetailsPanel.vue"

export default script