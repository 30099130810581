import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  "class": "execution-analysis-panel"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  "class": "empty"
};
var _hoisted_4 = {
  key: 1,
  "class": "execution-analysis-errors"
};
var _hoisted_5 = {
  key: 2,
  "class": "execution-analysis-jobs"
};
var _hoisted_6 = {
  "class": "execution-analysis-jobs-panel"
};
var _hoisted_7 = {
  key: 3,
  "class": "execution-analysis-logs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_hue_link = _resolveComponent("hue-link");
  var _component_LogsPanel = _resolveComponent("LogsPanel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.analysisAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.I18n('Select and execute a query to see the analysis.')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _ctx.analysisAvailable && _ctx.errors.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("h4", null, _toDisplayString(_ctx.I18n('Errors')), 1 /* TEXT */), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, function (error) {
    return _openBlock(), _createElementBlock("li", {
      key: error.message
    }, _toDisplayString(error.message), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true), _ctx.analysisAvailable && _ctx.jobsAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("h4", null, _toDisplayString(_ctx.I18n('Jobs')), 1 /* TEXT */), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobsWithUrls, function (job) {
    return _openBlock(), _createBlock(_component_hue_link, {
      key: job.url,
      url: job.url,
      target: "_blank"
    }, {
      "default": _withCtx(function () {
        return [_createTextVNode(_toDisplayString(job.name), 1 /* TEXT */)];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["url"]);
  }), 128 /* KEYED_FRAGMENT */))])])) : _createCommentVNode("v-if", true), _ctx.analysisAvailable ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("h4", null, _toDisplayString(_ctx.I18n('Logs')), 1 /* TEXT */), _createVNode(_component_LogsPanel, {
    "class": "execution-analysis-logs-panel",
    logs: _ctx.executionLogs
  }, null, 8 /* PROPS */, ["logs"])])) : _createCommentVNode("v-if", true)]);
}