import { defineComponent } from 'vue';
export default defineComponent({
  name: 'UdfDetailsPanel',
  props: {
    suggestion: {
      type: Object,
      required: true
    }
  },
  computed: {
    details: function details() {
      return this.suggestion.details;
    },
    udfName: function udfName() {
      return this.details.signature.substring(0, this.details.signature.indexOf('('));
    }
  }
});