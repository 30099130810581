import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "variable-substitution"
};
var _hoisted_2 = {
  "class": "variable-value"
};
var _hoisted_3 = {
  "class": "variable-label"
};
var _hoisted_4 = ["onUpdate:modelValue", "placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ComboBox = _resolveComponent("ComboBox");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeVariables, function (variable) {
    return _openBlock(), _createElementBlock("div", {
      key: variable.name
    }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(variable.name), 1 /* TEXT */), variable.meta.options.length === 0 ? _withDirectives((_openBlock(), _createElementBlock("input", {
      key: 0,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return variable.value = $event;
      },
      "class": "variable-input",
      autocomplete: "no",
      placeholder: variable.meta.placeholder
    }, null, 8 /* PROPS */, _hoisted_4)), [[_vModelText, variable.value]]) : (_openBlock(), _createBlock(_component_ComboBox, {
      key: 1,
      modelValue: variable.value,
      "onUpdate:modelValue": function onUpdateModelValue($event) {
        return variable.value = $event;
      },
      options: variable.meta.options
    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"]))])]);
  }), 128 /* KEYED_FRAGMENT */))]);
}