import { EXECUTABLE_UPDATED_TOPIC } from 'apps/editor/execution/events';
import { defineComponent, ref, toRefs, watch } from 'vue';
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import I18n from "../../../utils/i18n";
export default defineComponent({
  name: 'ExecuteLimitInput',
  props: {
    executable: {
      type: Object,
      "default": undefined
    }
  },
  emits: ['limit-changed'],
  setup: function setup(props) {
    var _toRefs = toRefs(props),
      executable = _toRefs.executable;
    var limit = ref(null);
    var subTracker = new SubscriptionTracker();
    var updateFromExecutable = function updateFromExecutable(updatedExecutable) {
      limit.value = updatedExecutable.executor.defaultLimit && updatedExecutable.executor.defaultLimit() || null;
    };
    subTracker.subscribe(EXECUTABLE_UPDATED_TOPIC, function (updatedExecutable) {
      if (executable.value && executable.value.id === updatedExecutable.id) {
        updateFromExecutable(updatedExecutable);
      }
    });
    watch(executable, function (newVal) {
      if (newVal) {
        updateFromExecutable(newVal);
      }
    }, {
      immediate: true
    });
    return {
      limit: limit,
      I18n: I18n
    };
  }
});