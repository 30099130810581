import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ace_editor = _resolveComponent("ace-editor");
  return _ctx.editorId ? (_openBlock(), _createBlock(_component_ace_editor, {
    key: 0,
    id: _ctx.editorId,
    "ace-options": _ctx.aceOptions,
    executor: _ctx.executor,
    "initial-cursor-position": _ctx.cursorPosition,
    "initial-value": _ctx.value,
    "sql-analyzer-provider": _ctx.sqlAnalyzerProvider,
    "sql-parser-provider": _ctx.sqlParserProvider,
    "sql-reference-provider": _ctx.sqlReferenceProvider,
    onAceCreated: _ctx.aceCreated,
    onCreateNewDoc: _ctx.createNewDoc,
    onCursorChanged: _ctx.cursorChanged,
    onSaveDoc: _ctx.saveDoc,
    onTogglePresentationMode: _ctx.togglePresentationMode,
    onValueChanged: _ctx.valueChanged
  }, null, 8 /* PROPS */, ["id", "ace-options", "executor", "initial-cursor-position", "initial-value", "sql-analyzer-provider", "sql-parser-provider", "sql-reference-provider", "onAceCreated", "onCreateNewDoc", "onCursorChanged", "onSaveDoc", "onTogglePresentationMode", "onValueChanged"])) : _createCommentVNode("v-if", true);
}