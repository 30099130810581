import { defineComponent, ref, toRefs } from 'vue';
import QueryHistoryTable from "./QueryHistoryTable.vue";
import { wrap } from 'vue/webComponentWrap';
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
var QueryHistoryTableKoBridge = defineComponent({
  name: 'QueryHistoryTableKoBridge',
  components: {
    QueryHistoryTable: QueryHistoryTable
  },
  props: {
    connectorObservable: {
      type: Object,
      "default": undefined
    }
  },
  emits: ['history-entry-clicked'],
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      connectorObservable = _toRefs.connectorObservable;
    var connector = ref(undefined);
    subTracker.trackObservable(connectorObservable, connector);
    return {
      connector: connector
    };
  }
});
export var COMPONENT_NAME = 'query-history-table-ko-bridge';
wrap(COMPONENT_NAME, QueryHistoryTableKoBridge);
export default QueryHistoryTableKoBridge;