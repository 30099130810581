// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import * as ko from 'knockout';
import huePubSub from 'utils/huePubSub';
import { EXECUTABLE_RESULT_UPDATED_TOPIC } from "../execution/events";
export var trackResult = function trackResult(activeExecutable, onChange) {
  if (!activeExecutable) {
    return {
      dispose: function dispose() {}
    };
  }
  var disposals = [];
  var executable = ko.unwrap(activeExecutable);
  if (ko.isObservable(activeExecutable)) {
    var koSub = activeExecutable.subscribe(function (newExecutable) {
      executable = newExecutable;
      onChange(executable.result);
    });
    disposals.push(function () {
      koSub.dispose();
    });
  } else if (!executable) {
    return {
      dispose: function dispose() {}
    };
  }
  var updateSub = huePubSub.subscribe(EXECUTABLE_RESULT_UPDATED_TOPIC, function (executionResult) {
    if (executionResult === executable.result) {
      onChange(executionResult);
    }
  });
  disposals.push(function () {
    updateSub.remove();
  });
  return {
    dispose: function dispose() {
      while (disposals.length) {
        disposals.pop()();
      }
    }
  };
};
export var attachTracker = function attachTracker(activeExecutable, id, target, trackedObservables) {
  var disposals = [];
  if (!activeExecutable) {
    return {
      dispose: function dispose() {}
    };
  }
  var ignoreObservableChange = false;
  var updateFromState = function updateFromState(state) {
    ignoreObservableChange = true;
    Object.keys(state).forEach(function (key) {
      return target[key](state[key]);
    });
    ignoreObservableChange = false;
  };
  updateFromState(trackedObservables);
  var sub = activeExecutable.subscribe(function (executable) {
    var state = Object.assign({}, trackedObservables, executable.observerState[id]);
    updateFromState(state);
  });
  disposals.push(function () {
    sub.dispose();
  });
  Object.keys(trackedObservables).forEach(function (observableAttr) {
    var sub = target[observableAttr].subscribe(function (val) {
      if (ignoreObservableChange || !activeExecutable()) {
        return;
      }
      if (!activeExecutable().observerState[id]) {
        activeExecutable().observerState[id] = {};
      }
      activeExecutable().observerState[id][observableAttr] = val;
    });
    disposals.push(function () {
      sub.dispose();
    });
  });
  return {
    dispose: function dispose() {
      while (disposals.length) {
        disposals.pop()();
      }
    }
  };
};