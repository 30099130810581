import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DropdownMenuOptions = _resolveComponent("DropdownMenuOptions");
  return _openBlock(), _createElementBlock("div", {
    "class": "ace-syntax-dropdown-container",
    style: _normalizeStyle(_ctx.position)
  }, [_createVNode(_component_DropdownMenuOptions, {
    "force-bottom-placement": true,
    open: _ctx.visible,
    options: _ctx.options,
    onClose: _ctx.closePanel,
    onOptionSelected: _ctx.optionSelected
  }, null, 8 /* PROPS */, ["open", "options", "onClose", "onOptionSelected"])], 4 /* STYLE */);
}