import { defineComponent, ref, toRefs } from 'vue';
import { wrap } from 'vue/webComponentWrap';
import EditorResizer from "./EditorResizer.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
var EditorResizerKoBridge = defineComponent({
  name: 'EditorResizerKoBridge',
  components: {
    EditorResizer: EditorResizer
  },
  props: {
    editorObservable: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      editorObservable = _toRefs.editorObservable;
    var editor = ref(null);
    subTracker.trackObservable(editorObservable, editor);
    return {
      editor: editor
    };
  }
});
export var COMPONENT_NAME = 'editor-resizer-ko-bridge';
wrap(COMPONENT_NAME, EditorResizerKoBridge);
export default EditorResizerKoBridge;