import { render } from "./ResultTable.vue?vue&type=template&id=0fc4f8ca"
import script from "./ResultTable.vue?vue&type=script&lang=ts"
export * from "./ResultTable.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0fc4f8ca"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0fc4f8ca', script)) {
    api.reload('0fc4f8ca', script)
  }
  
  module.hot.accept("./ResultTable.vue?vue&type=template&id=0fc4f8ca", () => {
    api.rerender('0fc4f8ca', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/result/ResultTable.vue"

export default script