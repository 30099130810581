import { defineComponent } from 'vue';
import { CategoryId } from "../Category";
import SqlText from "../../../../../../components/SqlText.vue";
import I18n from "../../../../../../utils/i18n";
export default defineComponent({
  name: 'PopularDetailsPanel',
  components: {
    SqlText: SqlText
  },
  props: {
    suggestion: {
      type: Object,
      required: true
    },
    connector: {
      type: Object,
      "default": undefined
    }
  },
  computed: {
    details: function details() {
      return this.suggestion.details;
    },
    dialect: function dialect() {
      return this.connector && this.connector.dialect;
    },
    popularityTitle: function popularityTitle() {
      if (this.suggestion.category.categoryId === CategoryId.PopularGroupBy || this.suggestion.category.categoryId === CategoryId.PopularOrderBy) {
        return "".concat(I18n('Workload percent'), ": ").concat(this.details.workloadPercent || '?', "%");
      }
      return "".concat(I18n('Relative popularity'), ": ").concat(this.details.relativePopularity || '?', "%");
    },
    title: function title() {
      switch (this.suggestion.category.categoryId) {
        case CategoryId.PopularFilter:
          return I18n('Popular filter');
        case CategoryId.PopularGroupBy:
          return I18n('Popular group by');
        case CategoryId.PopularOrderBy:
          return I18n('Popular order by');
        case CategoryId.PopularActiveJoin:
        case CategoryId.PopularJoin:
          return I18n('Popular join');
        case CategoryId.PopularJoinCondition:
          return I18n('Popular join condition');
      }
      return I18n('Popular');
    }
  }
});