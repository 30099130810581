import { defineComponent, ref, toRefs } from 'vue';
import { wrap } from 'vue/webComponentWrap';
import ResultTable from "./ResultTable.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
var ResultTableKoBridge = defineComponent({
  name: 'ResultTableKoBridge',
  components: {
    ResultTable: ResultTable
  },
  props: {
    executableObservable: {
      type: Object,
      "default": null
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      executableObservable = _toRefs.executableObservable;
    var executable = ref(null);
    subTracker.trackObservable(executableObservable, executable);
    return {
      executable: executable
    };
  }
});
export var COMPONENT_NAME = 'result-table-ko-bridge';
wrap(COMPONENT_NAME, ResultTableKoBridge);
export default ResultTableKoBridge;