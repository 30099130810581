import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "autocompleter-header"
};
var _hoisted_2 = {
  "class": "autocompleter-details-contents"
};
var _hoisted_3 = {
  "class": "autocompleter-details-contents-inner"
};
var _hoisted_4 = {
  "class": "details-code"
};
var _hoisted_5 = {
  "class": "details-code"
};
var _hoisted_6 = {
  "class": "details-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.suggestion.value), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.I18n('Type:')) + " ", 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString(_ctx.details.type), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_5, [_createTextVNode(_toDisplayString(_ctx.I18n('Default:')) + " ", 1 /* TEXT */), _createElementVNode("span", null, _toDisplayString(_ctx.details["default"]), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.details.description), 1 /* TEXT */)])])]);
}