import { render } from "./VariableSubstitution.vue?vue&type=template&id=b28491d8"
import script from "./VariableSubstitution.vue?vue&type=script&lang=ts"
export * from "./VariableSubstitution.vue?vue&type=script&lang=ts"

import "./VariableSubstitution.vue?vue&type=style&index=0&id=b28491d8&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "b28491d8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b28491d8', script)) {
    api.reload('b28491d8', script)
  }
  
  module.hot.accept("./VariableSubstitution.vue?vue&type=template&id=b28491d8", () => {
    api.rerender('b28491d8', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/variableSubstitution/VariableSubstitution.vue"

export default script