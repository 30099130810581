// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import HueColors from "../../../../utils/hueColors";
import html2text from 'utils/html/html2text';
import { CHART_MAP_TYPE, CHART_SORTING } from "./ko.resultChart";

// The leaflet map can freeze the browser with numbers outside the map
var MIN_LAT = -90;
var MAX_LAT = 90;
var MIN_LNG = -180;
var MAX_LNG = 180;
var isNotNullForCharts = function isNotNullForCharts(val) {
  return val !== 'NULL' && val !== null;
};
export var pieChartTransformer = function pieChartTransformer(rawDatum) {
  var data = [];
  if (rawDatum.chartX() != null && rawDatum.chartYSingle() != null) {
    var valueIndex = -1;
    var labelIndex = -1;
    rawDatum.meta().some(function (col, index) {
      if (col.name === rawDatum.chartX()) {
        labelIndex = index;
      }
      if (col.name === rawDatum.chartYSingle()) {
        valueIndex = index;
      }
      return valueIndex !== -1 && labelIndex !== -1;
    });
    var colors = HueColors.cuiD3Scale();
    rawDatum.data().forEach(function (item, index) {
      if (isNotNullForCharts(item[valueIndex])) {
        var val = item[valueIndex] * 1;
        if (isNaN(val)) {
          val = 0;
        }
        data.push({
          label: html2text(item[labelIndex]),
          value: val,
          color: colors[index % colors.length],
          obj: item
        });
      }
    });
  }
  if (rawDatum.chartSorting() === CHART_SORTING.ASC) {
    data.sort(function (a, b) {
      return a.value - b.value;
    });
  } else if (rawDatum.chartSorting() === CHART_SORTING.DESC) {
    data.sort(function (a, b) {
      return b.value - a.value;
    });
  }
  if (rawDatum.chartLimit()) {
    data = data.slice(0, rawDatum.chartLimit());
  }
  return data;
};
export var mapChartTransformer = function mapChartTransformer(rawDatum) {
  var datum = [];
  if (rawDatum.chartX() != null && rawDatum.chartYSingle() != null) {
    var regionIndex = -1;
    var valueIndex = -1;
    rawDatum.meta().some(function (col, idx) {
      if (col.name === rawDatum.chartX()) {
        regionIndex = idx;
      }
      if (col.name === rawDatum.chartYSingle()) {
        valueIndex = idx;
      }
      return regionIndex !== -1 && valueIndex !== -1;
    });
    rawDatum.data().forEach(function (item) {
      if (isNotNullForCharts(item[valueIndex]) && isNotNullForCharts(item[regionIndex])) {
        datum.push({
          label: item[regionIndex],
          value: item[valueIndex],
          obj: item
        });
      }
    });
  }
  if (rawDatum.chartLimit()) {
    datum = datum.slice(0, rawDatum.chartLimit());
  }
  return datum;
};
export var leafletMapChartTransformer = function leafletMapChartTransformer(rawDatum) {
  var datum = [];
  if (rawDatum.chartX() != null && rawDatum.chartYSingle() != null) {
    var latIndex = -1;
    var lngIndex = -1;
    var labelIndex = -1;
    var heatIndex = -1;
    rawDatum.meta().some(function (col, idx) {
      if (col.name === rawDatum.chartX()) {
        latIndex = idx;
      }
      if (col.name === rawDatum.chartYSingle()) {
        lngIndex = idx;
      }
      if (col.name === rawDatum.chartMapLabel()) {
        labelIndex = idx;
      }
      if (col.name === rawDatum.chartMapHeat()) {
        heatIndex = idx;
      }
      return latIndex !== -1 && lngIndex !== -1 && labelIndex !== -1 && heatIndex !== -1;
    });
    rawDatum.data().forEach(function (item) {
      if (isNotNullForCharts(item[latIndex]) && isNotNullForCharts(item[lngIndex])) {
        datum.push({
          lat: Math.min(Math.max(MIN_LAT, item[latIndex]), MAX_LAT),
          lng: Math.min(Math.max(MIN_LNG, item[lngIndex]), MAX_LNG),
          label: labelIndex !== -1 ? html2text(item[labelIndex]) : undefined,
          isHeat: rawDatum.chartMapType() === CHART_MAP_TYPE.HEAT,
          intensity: heatIndex > -1 ? !isNaN(item[heatIndex] * 1) ? item[heatIndex] * 1 : null : null,
          obj: item
        });
      }
    });
  }
  if (rawDatum.chartLimit()) {
    datum = datum.slice(0, rawDatum.chartLimit());
  }
  return datum;
};
export var timelineChartTransformer = function timelineChartTransformer(rawDatum) {
  var datum = [];
  var plottedSeries = 0;
  rawDatum.meta().forEach(function (meta) {
    if (rawDatum.chartYMulti().indexOf(meta.name) > -1) {
      var col = meta.name;
      var valueIndex = -1;
      var labelIndex = -1;
      rawDatum.meta().some(function (icol, idx) {
        if (icol.name === rawDatum.chartX()) {
          labelIndex = idx;
        }
        if (icol.name === col) {
          valueIndex = idx;
        }
        return labelIndex !== -1 && valueIndex !== -1;
      });
      if (valueIndex > -1) {
        var values = [];
        var colors = HueColors.cuiD3Scale();
        rawDatum.data().forEach(function (item) {
          if (isNotNullForCharts(item[labelIndex]) && isNotNullForCharts(item[valueIndex])) {
            values.push({
              series: plottedSeries,
              x: new Date(moment(html2text(item[labelIndex])).valueOf()),
              y: item[valueIndex] * 1,
              color: colors[plottedSeries % colors.length],
              obj: item
            });
          }
        });
        if (rawDatum.chartSorting() === CHART_SORTING.ASC) {
          values.sort(function (a, b) {
            return a.y - b.y;
          });
        } else if (rawDatum.chartSorting() === CHART_SORTING.DESC) {
          values.sort(function (a, b) {
            return b.y - a.y;
          });
        }
        if (rawDatum.chartLimit()) {
          values = values.slice(0, rawDatum.chartLimit());
        }
        datum.push({
          key: col,
          values: values
        });
        plottedSeries++;
      }
    }
  });
  return datum;
};
export var multiSerieChartTransformer = function multiSerieChartTransformer(rawDatum) {
  var datum = [];
  if (rawDatum.chartX() != null && rawDatum.chartYMulti().length > 0) {
    var plottedSerie = 0;
    if (typeof rawDatum.chartXPivot() !== 'undefined') {
      var valueIndex = -1;
      var labelIndex = -1;
      var isDate = false;
      rawDatum.meta().some(function (col, idx) {
        if (col.name === rawDatum.chartX()) {
          isDate = col.type.toUpperCase().indexOf('DATE') > -1;
          labelIndex = idx;
        }
        if (col.name === rawDatum.chartYSingle()) {
          valueIndex = idx;
        }
        return labelIndex !== -1 && valueIndex !== -1;
      });
      rawDatum.meta().forEach(function (meta, cnt) {
        if (rawDatum.chartXPivot() === meta.name) {
          var pivotIndex = cnt;
          var colors = HueColors.cuiD3Scale();
          var pivotValues = rawDatum.data().map(function (p) {
            return p[pivotIndex];
          });
          pivotValues = pivotValues.filter(function (item, pos) {
            return pivotValues.indexOf(item) === pos;
          });
          pivotValues.forEach(function (val, pivotCnt) {
            var values = [];
            rawDatum.data().forEach(function (item) {
              if (item[pivotIndex] === val) {
                if (isNotNullForCharts(item[valueIndex]) && isNotNullForCharts(item[labelIndex])) {
                  values.push({
                    x: isDate ? moment(item[labelIndex]) : html2text(item[labelIndex]),
                    y: item[valueIndex] * 1,
                    color: colors[pivotCnt % colors.length],
                    obj: item
                  });
                }
              }
            });
            datum.push({
              key: html2text(val),
              values: values
            });
          });
        }
      });

      // fills in missing values
      var longest = 0;
      var allXValues = [];
      datum.forEach(function (d) {
        d.values.forEach(function (val) {
          if (allXValues.indexOf(val.x) === -1) {
            allXValues.push(val.x);
          }
        });
      });
      datum.forEach(function (d) {
        allXValues.forEach(function (val) {
          if (!d.values.some(function (item) {
            return item.x === val;
          })) {
            var zeroObj = $.extend({}, d.values[0]);
            zeroObj.y = 0;
            zeroObj.x = val;
            d.values.push(zeroObj);
          }
        });
        if (d.values.length > longest) {
          longest = d.values.length;
        }
      });

      // this is to avoid D3 js errors when the data the user is trying to display is bogus
      if (allXValues.length < longest) {
        datum.forEach(function (d) {
          for (var i = d.values.length; i < longest; i++) {
            var zeroObj = $.extend({}, d.values[0]);
            zeroObj.y = 0;
            zeroObj.x = '';
            d.values.push(zeroObj);
          }
        });
      }
      if (rawDatum.chartLimit()) {
        datum = datum.slice(0, rawDatum.chartLimit());
      }
      if (rawDatum.chartSorting() === CHART_SORTING.DESC) {
        datum.forEach(function (d) {
          d.values.sort(function (a, b) {
            if (a.x > b.x) {
              return -1;
            }
            if (a.x < b.x) {
              return 1;
            }
            return 0;
          });
        });
      } else {
        datum.forEach(function (d) {
          d.values.sort(function (a, b) {
            if (a.x > b.x) {
              return 1;
            }
            if (a.x < b.x) {
              return -1;
            }
            return 0;
          });
        });
      }
    } else {
      rawDatum.meta().forEach(function (meta) {
        if (rawDatum.chartYMulti().indexOf(meta.name) > -1) {
          var col = meta.name;
          var _valueIndex = -1;
          var _labelIndex = -1;
          var _isDate = false;
          rawDatum.meta().some(function (icol, idx) {
            if (icol.name === rawDatum.chartX()) {
              _isDate = icol.type.toUpperCase().indexOf('DATE') > -1;
              _labelIndex = idx;
            }
            if (icol.name === col) {
              _valueIndex = idx;
            }
            return _labelIndex !== -1 && _valueIndex !== -1;
          });
          if (_valueIndex > -1) {
            var values = [];
            var colors = HueColors.cuiD3Scale();
            rawDatum.data().forEach(function (item, index) {
              if (isNotNullForCharts(item[_valueIndex]) && isNotNullForCharts(item[_labelIndex])) {
                values.push({
                  series: plottedSerie,
                  x: _isDate ? moment(item[_labelIndex]) : html2text(item[_labelIndex]),
                  y: item[_valueIndex] * 1,
                  color: colors[index % colors.length],
                  obj: item
                });
              }
            });
            if (rawDatum.chartSorting() === CHART_SORTING.ASC) {
              values.sort(function (a, b) {
                return a.y - b.y;
              });
            } else if (rawDatum.chartSorting() === CHART_SORTING.DESC) {
              values.sort(function (a, b) {
                return b.y - a.y;
              });
            }
            if (rawDatum.chartLimit()) {
              values = values.slice(0, rawDatum.chartLimit());
            }
            datum.push({
              key: col,
              values: values
            });
            plottedSerie++;
          }
        }
      });
    }
  }
  return datum;
};
export var scatterChartTransformer = function scatterChartTransformer(rawDatum) {
  var datumIndex = {};
  if (rawDatum.chartX() != null && rawDatum.chartYSingle() != null) {
    var xIndex = -1;
    var yIndex = -1;
    var sizeIndex = -1;
    var groupIndex = -1;
    rawDatum.meta().some(function (col, idx) {
      if (col.name === rawDatum.chartX()) {
        xIndex = idx;
      }
      if (col.name === rawDatum.chartYSingle()) {
        yIndex = idx;
      }
      if (col.name === rawDatum.chartScatterSize()) {
        sizeIndex = idx;
      }
      if (col.name === rawDatum.chartScatterGroup()) {
        groupIndex = idx;
      }
      return xIndex !== -1 && yIndex !== -1 && sizeIndex !== -1 && groupIndex !== -1;
    });
    if (xIndex > -1 && yIndex > -1) {
      var createAndAddToArray = function createAndAddToArray(key, item) {
        if (!datumIndex[key]) {
          datumIndex[key] = [];
        }
        if (isNotNullForCharts(item[xIndex]) && isNotNullForCharts(item[yIndex])) {
          datumIndex[key].push({
            x: item[xIndex],
            y: item[yIndex],
            shape: 'circle',
            size: sizeIndex > -1 ? item[sizeIndex] : 100,
            obj: item
          });
        }
      };
      if (groupIndex > -1) {
        rawDatum.data().forEach(function (item) {
          createAndAddToArray(item[groupIndex], item);
        });
      } else {
        rawDatum.data().forEach(function (item) {
          createAndAddToArray('distro', item);
        });
      }
    }
  }
  var datum = [];
  Object.keys(datumIndex).forEach(function (key) {
    datum.push({
      key: key,
      values: rawDatum.chartLimit() ? datumIndex[key].slice(0, rawDatum.chartLimit()) : datumIndex[key]
    });
  });
  return datum;
};