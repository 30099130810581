import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_executable_actions = _resolveComponent("executable-actions");
  return _openBlock(), _createBlock(_component_executable_actions, {
    executable: _ctx.executable,
    "before-execute": _ctx.beforeExecute,
    onLimitChanged: _ctx.limitChanged,
    onExecuteFailed: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('execute-failed', $event);
    }),
    onExecuteSuccessful: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('execute-successful', $event);
    })
  }, null, 8 /* PROPS */, ["executable", "before-execute", "onLimitChanged"]);
}