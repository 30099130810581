import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-51ea8929");
var _hoisted_1 = {
  autocomplete: "off",
  "class": "inline-block margin-left-10"
};
var _hoisted_2 = ["placeholder"];
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("form", _hoisted_1, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.limit = $event;
    }),
    "data-hue-analytics": "editor:limit-input-click",
    "class": "input-small limit-input",
    type: "number",
    autocorrect: "off",
    autocomplete: "do-not-autocomplete",
    autocapitalize: "off",
    spellcheck: "false",
    placeholder: _ctx.I18n('Limit'),
    onChange: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('limit-changed', _ctx.limit);
    })
  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2), [[_vModelText, _ctx.limit]])]);
}