import { defineComponent, ref, toRefs } from 'vue';
import "./AceSyntaxDropdown.scss";
import { SQL_SYNTAX_DROPDOWN_SHOW_TOPIC } from "./events";
import DropdownMenuOptions from "../../../../components/dropdown/DropdownMenuOptions.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import huePubSub from 'utils/huePubSub';
import I18n from "../../../../utils/i18n";
import { getFromLocalStorage, setInLocalStorage } from 'utils/storageUtils';
export default defineComponent({
  name: 'AceSyntaxDropdown',
  components: {
    DropdownMenuOptions: DropdownMenuOptions
  },
  props: {
    editor: {
      type: Object,
      required: true
    },
    editorId: {
      type: String,
      required: true
    }
  },
  emits: [],
  setup: function setup(props) {
    var _toRefs = toRefs(props),
      editor = _toRefs.editor,
      editorId = _toRefs.editorId;
    var subTracker = new SubscriptionTracker();
    var visible = ref(false);
    var range = ref();
    var options = ref([]);
    var position = ref();
    var closePanel = function closePanel() {
      options.value = [];
      range.value = undefined;
      visible.value = false;
    };
    var optionSelected = function optionSelected(_ref) {
      var suppress = _ref.suppress,
        value = _ref.value;
      if (suppress) {
        var currentSuppressedRules = getFromLocalStorage('hue.syntax.checker.suppressedRules', {});
        currentSuppressedRules[suppress] = true;
        setInLocalStorage('hue.syntax.checker.suppressedRules', currentSuppressedRules);
        huePubSub.publish('editor.refresh.statement.locations', editorId.value);
      } else if (range.value) {
        editor.value.session.replace(range.value, value);
      }
    };
    subTracker.subscribe('sql.syntax.dropdown.hide', closePanel);
    subTracker.subscribe(SQL_SYNTAX_DROPDOWN_SHOW_TOPIC, function (details) {
      if (details.editorId !== editorId.value) {
        return;
      }
      var newOptions = details.data.expected.map(function (expected) {
        return {
          label: expected.text,
          value: expected.text
        };
      });
      if (details.data.ruleId) {
        newOptions.push({
          label: '-',
          value: '_divider_',
          divider: true
        });
        newOptions.push({
          label: I18n('Ignore this type of error'),
          value: '_suppress_',
          suppress: details.data.ruleId + details.data.text.toLowerCase()
        });
      }
      position.value = {
        top: "".concat(details.source.bottom, "px"),
        left: "".concat(details.source.left, "px")
      };
      range.value = details.range;
      options.value = newOptions;
      visible.value = true;
    });
    return {
      closePanel: closePanel,
      options: options,
      optionSelected: optionSelected,
      position: position,
      visible: visible
    };
  }
});