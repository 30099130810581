import { defineComponent, toRefs, computed } from 'vue';
import { ExecutionStatus } from "../execution/sqlExecutable";
import I18n from "../../../utils/i18n";
export default defineComponent({
  name: 'ExecutionStatusIcon',
  props: {
    status: {
      type: String,
      "default": null
    }
  },
  setup: function setup(props) {
    var _toRefs = toRefs(props),
      status = _toRefs.status;
    var statusSpec = computed(function () {
      switch (status.value) {
        case ExecutionStatus.expired:
          return {
            title: I18n('Expired'),
            faIcon: 'fa-unlink'
          };
        case ExecutionStatus.available:
          return {
            title: I18n('Available'),
            faIcon: 'fa-check'
          };
        case ExecutionStatus.failed:
          return {
            title: I18n('Failed'),
            faIcon: 'fa-exclamation'
          };
        case ExecutionStatus.streaming:
          return {
            title: I18n('Streaming'),
            faIcon: 'fa-fighter-jet'
          };
        case ExecutionStatus.running:
          return {
            title: I18n('Running'),
            faIcon: 'fa-fighter-jet'
          };
      }
      return null;
    });
    return {
      statusSpec: statusSpec
    };
  }
});