import { defineComponent } from 'vue';
export default defineComponent({
  name: 'MatchedText',
  props: {
    suggestion: {
      type: Object,
      required: true
    },
    filter: {
      type: String,
      required: true
    },
    isComment: {
      type: Boolean,
      required: false,
      "default": false
    }
  },
  computed: {
    content: function content() {
      var value = (this.isComment ? this.suggestion.details.comment : this.suggestion.value) || '';
      if (this.filter && typeof this.suggestion.matchIndex !== 'undefined' && this.suggestion.matchIndex > -1 && typeof this.suggestion.matchLength !== 'undefined' && (!this.isComment && !this.suggestion.matchComment || this.isComment && this.suggestion.matchComment)) {
        var before = value.substring(0, this.suggestion.matchIndex);
        var match = value.substring(this.suggestion.matchIndex, this.suggestion.matchIndex + this.suggestion.matchLength);
        var after = value.substring(this.suggestion.matchIndex + this.suggestion.matchLength);
        return "".concat(before, "<b>").concat(match, "</b>").concat(after);
      }
      return value || '';
    }
  }
});