import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  "class": "autocompleter-suggestions"
};
var _hoisted_2 = {
  key: 0,
  "class": "autocompleter-header"
};
var _hoisted_3 = {
  key: 0,
  "class": "autocompleter-categories"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  "class": "autocompleter-spinner"
};
var _hoisted_6 = {
  ref: "entriesScrollDiv",
  "class": "autocompleter-entries"
};
var _hoisted_7 = {
  ref: "entriesDiv"
};
var _hoisted_8 = ["onClick", "onMouseover"];
var _hoisted_9 = {
  "class": "autocompleter-suggestion-value"
};
var _hoisted_10 = {
  key: 0,
  "class": "fa fa-key"
};
var _hoisted_11 = {
  "class": "autocompleter-suggestion-meta"
};
var _hoisted_12 = {
  key: 0,
  "class": "fa fa-star-o popular-color"
};
var _hoisted_13 = {
  key: 0,
  "class": "autocompleter-details"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_spinner = _resolveComponent("spinner");
  var _component_matched_text = _resolveComponent("matched-text");
  var _directive_click_outside = _resolveDirective("click-outside");
  return _ctx.visible ? _withDirectives((_openBlock(), _createElementBlock("div", {
    key: 0,
    "class": "hue-ace-autocompleter",
    style: _normalizeStyle({
      top: _ctx.top + 'px',
      left: _ctx.left + 'px'
    })
  }, [_createElementVNode("div", _hoisted_1, [_ctx.availableCategories.length > 1 || _ctx.loading ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createCommentVNode(" ko if: suggestions.availableCategories().length > 1 "), _ctx.availableCategories.length > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableCategories, function (category) {
    return _openBlock(), _createElementBlock("div", {
      key: category.label,
      style: _normalizeStyle({
        'border-color': _ctx.activeCategory.categoryId === category.categoryId ? category.color : 'transparent'
      }),
      "class": _normalizeClass({
        active: _ctx.activeCategory.categoryId === category.categoryId
      }),
      onClick: function onClick($event) {
        return _ctx.clickCategory(category, $event);
      }
    }, _toDisplayString(category.label), 15 /* TEXT, CLASS, STYLE, PROPS */, _hoisted_4);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, [_createVNode(_component_spinner, {
    spin: _ctx.loading,
    size: "small"
  }, null, 8 /* PROPS */, ["spin"])])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtered, function (suggestion, index) {
    return _openBlock(), _createElementBlock("div", {
      key: _ctx.filter + _ctx.activeCategory.categoryId + suggestion.category.categoryId + suggestion.value,
      "class": _normalizeClass(["autocompleter-suggestion", {
        selected: index === _ctx.selectedIndex
      }]),
      onClick: function onClick($event) {
        return _ctx.clickSuggestion(index);
      },
      onMouseover: function onMouseover($event) {
        return _ctx.hoveredIndex = index;
      },
      onMouseout: _cache[0] || (_cache[0] = function ($event) {
        return _ctx.hoveredIndex = null;
      })
    }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
      "class": "autocompleter-dot",
      style: _normalizeStyle({
        'background-color': suggestion.category.color
      })
    }, null, 4 /* STYLE */), _createVNode(_component_matched_text, {
      suggestion: suggestion,
      filter: _ctx.filter
    }, null, 8 /* PROPS */, ["suggestion", "filter"]), suggestion.details && suggestion.details.hasOwnProperty('primary_key') ? (_openBlock(), _createElementBlock("i", _hoisted_10)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_11, [suggestion.popular ? (_openBlock(), _createElementBlock("i", _hoisted_12)) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(suggestion.meta), 1 /* TEXT */)])], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_8);
  }), 128 /* KEYED_FRAGMENT */))], 512 /* NEED_PATCH */)], 512 /* NEED_PATCH */)]), _ctx.detailsComponent ? (_openBlock(), _createElementBlock("div", _hoisted_13, [(_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.detailsComponent), {
    suggestion: _ctx.focusedEntry,
    connector: _ctx.connector
  }, null, 8 /* PROPS */, ["suggestion", "connector"]))])) : _createCommentVNode("v-if", true)], 4 /* STYLE */)), [[_directive_click_outside, _ctx.clickOutside]]) : _createCommentVNode("v-if", true);
}