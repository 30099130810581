function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { EXECUTABLE_LOGS_UPDATED_TOPIC, EXECUTABLE_UPDATED_TOPIC } from 'apps/editor/execution/events';
import { debounce } from 'lodash';
import { defineComponent, computed, onBeforeUnmount, ref, reactive } from 'vue';
import SqlExecutable, { ExecutionStatus } from 'apps/editor/execution/sqlExecutable';
import HueLink from "../../../../components/HueLink.vue";
import LogsPanel from "../../../../components/LogsPanel.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import I18n from "../../../../utils/i18n";
export default defineComponent({
  name: 'ExecutionAnalysisPanel',
  components: {
    HueLink: HueLink,
    LogsPanel: LogsPanel
  },
  props: {
    executable: {
      type: SqlExecutable,
      "default": undefined
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    onBeforeUnmount(subTracker.dispose.bind(subTracker));
    var analysisAvailable = ref(false);
    var executionLogs = ref('');
    var jobs = reactive([]);
    var errors = reactive([]);
    var jobsAvailable = computed(function () {
      return !!jobs.length;
    });
    var jobsWithUrls = computed(function () {
      return jobs.filter(function (job) {
        return job.url;
      });
    });
    var debouncedUpdate = debounce(function (executable) {
      var status = executable.status,
        logs = executable.logs;
      executionLogs.value = logs.fullLog;
      jobs.splice.apply(jobs, [0, jobs.length].concat(_toConsumableArray(logs.jobs)));
      errors.splice.apply(errors, [0, errors.length].concat(_toConsumableArray(logs.errors)));
      analysisAvailable.value = status !== ExecutionStatus.ready || !!errors.length;
    }, 5);
    var updateFromExecutable = function updateFromExecutable(executable) {
      if (!props.executable || props.executable.id !== executable.id) {
        return;
      }
      debouncedUpdate.cancel();
      debouncedUpdate(executable);
    };
    updateFromExecutable(props.executable);
    subTracker.subscribe(EXECUTABLE_UPDATED_TOPIC, updateFromExecutable);
    subTracker.subscribe(EXECUTABLE_LOGS_UPDATED_TOPIC, function (executionLogs) {
      updateFromExecutable(executionLogs.executable);
    });
    return {
      analysisAvailable: analysisAvailable,
      executionLogs: executionLogs,
      jobs: jobs,
      jobsAvailable: jobsAvailable,
      jobsWithUrls: jobsWithUrls,
      errors: errors,
      I18n: I18n
    };
  }
});