import { render } from "./QueryHistoryTable.vue?vue&type=template&id=78c2d087"
import script from "./QueryHistoryTable.vue?vue&type=script&lang=ts"
export * from "./QueryHistoryTable.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "78c2d087"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('78c2d087', script)) {
    api.reload('78c2d087', script)
  }
  
  module.hot.accept("./QueryHistoryTable.vue?vue&type=template&id=78c2d087", () => {
    api.rerender('78c2d087', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/QueryHistoryTable.vue"

export default script