import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "ace-editor-component"
};
var _hoisted_2 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ace_autocomplete = _resolveComponent("ace-autocomplete");
  var _component_ace_syntax_dropdown = _resolveComponent("ace-syntax-dropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    id: _ctx.id,
    ref: "editorElement",
    "class": "ace-editor"
  }, null, 8 /* PROPS */, _hoisted_2), _ctx.editor && _ctx.autocompleteParser ? (_openBlock(), _createBlock(_component_ace_autocomplete, {
    key: 0,
    "autocomplete-parser": _ctx.autocompleteParser,
    "sql-reference-provider": _ctx.sqlReferenceProvider,
    "sql-analyzer-provider": _ctx.sqlAnalyzerProvider,
    editor: _ctx.editor,
    "editor-id": _ctx.id,
    executor: _ctx.executor
  }, null, 8 /* PROPS */, ["autocomplete-parser", "sql-reference-provider", "sql-analyzer-provider", "editor", "editor-id", "executor"])) : _createCommentVNode("v-if", true), _ctx.editor ? (_openBlock(), _createBlock(_component_ace_syntax_dropdown, {
    key: 1,
    editor: _ctx.editor,
    "editor-id": _ctx.id
  }, null, 8 /* PROPS */, ["editor", "editor-id"])) : _createCommentVNode("v-if", true)]);
}