import { render } from "./PopularDetailsPanel.vue?vue&type=template&id=270f5b51"
import script from "./PopularDetailsPanel.vue?vue&type=script&lang=ts"
export * from "./PopularDetailsPanel.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "270f5b51"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('270f5b51', script)) {
    api.reload('270f5b51', script)
  }
  
  module.hot.accept("./PopularDetailsPanel.vue?vue&type=template&id=270f5b51", () => {
    api.rerender('270f5b51', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/aceEditor/autocomplete/details/PopularDetailsPanel.vue"

export default script