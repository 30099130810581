import { defineComponent, ref, toRefs } from 'vue';
import ExecutableActions from "./ExecutableActions.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import hueAnalytics from "../../../../js/utils/hueAnalytics";
import { wrap } from 'vue/webComponentWrap';
var ExecutableActionsKoBridge = defineComponent({
  name: 'ExecutableActionsKoBridge',
  components: {
    ExecutableActions: ExecutableActions
  },
  props: {
    executableObservable: {
      type: Object,
      "default": undefined
    },
    beforeExecute: {
      type: Object,
      "default": undefined
    }
  },
  emits: ['execute-failed', 'execute-successful'],
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      executableObservable = _toRefs.executableObservable;
    var executable = ref(null);
    subTracker.trackObservable(executableObservable, executable);
    return {
      executable: executable
    };
  },
  methods: {
    limitChanged: function limitChanged(limit) {
      hueAnalytics.log('editor', 'limit-changed/' + limit);
      if (this.executable && this.executable.executor.defaultLimit) {
        this.executable.executor.defaultLimit(limit);
      }
    }
  }
});
export var COMPONENT_NAME = 'executable-actions-ko-bridge';
wrap(COMPONENT_NAME, ExecutableActionsKoBridge);
export default ExecutableActionsKoBridge;