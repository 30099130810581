import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_PresentationMode = _resolveComponent("PresentationMode");
  return _ctx.executor ? (_openBlock(), _createBlock(_component_PresentationMode, {
    key: 0,
    executor: _ctx.executor,
    title: _ctx.title,
    description: _ctx.description,
    onBeforeExecute: _ctx.onBeforeExecute,
    onClose: _ctx.onClose,
    onVariablesChanged: _ctx.onVariablesChanged
  }, null, 8 /* PROPS */, ["executor", "title", "description", "onBeforeExecute", "onClose", "onVariablesChanged"])) : _createCommentVNode("v-if", true);
}