import { defineComponent, ref, toRefs } from 'vue';
import { wrap } from 'vue/webComponentWrap';
import ExecutionAnalysisPanel from "./ExecutionAnalysisPanel.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
var ExecutionAnalysisPanelKoBridge = defineComponent({
  name: 'ExecutionAnalysisPanelKoBridge',
  components: {
    ExecutionAnalysisPanel: ExecutionAnalysisPanel
  },
  props: {
    executableObservable: {
      type: Object,
      "default": null
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      executableObservable = _toRefs.executableObservable;
    var executable = ref(null);
    subTracker.trackObservable(executableObservable, executable);
    return {
      executable: executable
    };
  }
});
export var COMPONENT_NAME = 'execution-analysis-panel-ko-bridge';
wrap(COMPONENT_NAME, ExecutionAnalysisPanelKoBridge);
export default ExecutionAnalysisPanelKoBridge;