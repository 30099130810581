function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import SqlExecutable from 'apps/editor/execution/sqlExecutable';
export var syncSqlExecutables = function syncSqlExecutables(executor, statementDetails) {
  var allNewStatements = [].concat(_toConsumableArray(statementDetails.precedingStatements), [statementDetails.activeStatement], _toConsumableArray(statementDetails.followingStatements));
  var existingExecutables = _toConsumableArray(executor.executables);
  var result = {
    all: [],
    edited: [],
    lost: [],
    selected: []
  };
  var activeDatabase = executor.database();
  var currentSelectedIndex = 0;
  var activeStatementIndex = 0;
  allNewStatements.forEach(function (parsedStatement, index) {
    if (/USE/i.test(parsedStatement.firstToken)) {
      var dbMatch = parsedStatement.statement.match(/use\s+([^;]+)/i);
      if (dbMatch) {
        activeDatabase = dbMatch[1];
      }
    }
    var executable = existingExecutables[index];
    if (executable) {
      var edited = executable.database !== activeDatabase || parsedStatement.statement !== executable.parsedStatement.statement;
      existingExecutables[index] = undefined; // undefined = not lost below
      executable.database = activeDatabase;
      executable.parsedStatement = parsedStatement;
      if (edited) {
        executable.edited = true;
        result.edited.push(executable);
      }
    } else {
      executable = new SqlExecutable({
        parsedStatement: parsedStatement,
        database: activeDatabase,
        executor: executor
      });
      executable.edited = true;
    }
    if (parsedStatement === statementDetails.activeStatement) {
      activeStatementIndex = index;
    }
    result.all.push(executable);
    if (currentSelectedIndex < statementDetails.selectedStatements.length && parsedStatement === statementDetails.selectedStatements[currentSelectedIndex]) {
      result.selected.push(executable);
      currentSelectedIndex++;
    }
  });
  result.lost = existingExecutables.filter(function (executable) {
    return typeof executable !== 'undefined';
  });
  return _objectSpread(_objectSpread({}, result), {}, {
    active: result.all[activeStatementIndex]
  });
};