import { render } from "./ExecutableActionsKoBridge.vue?vue&type=template&id=cdd30ef6"
import script from "./ExecutableActionsKoBridge.vue?vue&type=script&lang=ts"
export * from "./ExecutableActionsKoBridge.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "cdd30ef6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('cdd30ef6', script)) {
    api.reload('cdd30ef6', script)
  }
  
  module.hot.accept("./ExecutableActionsKoBridge.vue?vue&type=template&id=cdd30ef6", () => {
    api.rerender('cdd30ef6', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/ExecutableActionsKoBridge.vue"

export default script