// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import defer from 'utils/timing/defer';
import noop from 'utils/timing/noop';
export var attachPredictTypeahead = function attachPredictTypeahead(editor, connector, sqlAnalyzerProvider) {
  if (!sqlAnalyzerProvider) {
    return {
      dispose: noop
    };
  }
  var activePredict;
  var sqlAnalyzer = sqlAnalyzerProvider.getSqlAnalyzer(connector);
  var addPredictElement = function addPredictElement(text) {
    var element = document.createElement('div');
    element.innerText = text;
    element.style.marginLeft = '4px';
    element.classList.add('ace_invisible');
    element.classList.add('ace_emptyMessage');
    editor.renderer.scroller.append(element);
    return {
      text: text,
      element: element
    };
  };
  var removeActivePredict = function removeActivePredict() {
    if (activePredict) {
      activePredict.element.remove();
      activePredict = undefined;
    }
  };
  var setActivePredict = function setActivePredict(text) {
    removeActivePredict();
    activePredict = addPredictElement(text);
  };
  var activePredictPromise;
  var lastPrediction;
  var updatePredictTypeahead = function updatePredictTypeahead() {
    var editorText = editor.getValue();
    try {
      if (editorText.length && !activePredict) {
        activePredictPromise = sqlAnalyzer.predict({
          beforeCursor: editor.getTextBeforeCursor(),
          afterCursor: editor.getTextAfterCursor()
        });
        activePredictPromise.then(function (_ref) {
          var prediction = _ref.prediction;
          if (prediction !== lastPrediction) {
            var beforeCursor = editor.getTextBeforeCursor();
            if (prediction && prediction.toLowerCase().startsWith(beforeCursor.toLowerCase())) {
              setActivePredict(beforeCursor + prediction.slice(beforeCursor.length));
            } else {
              removeActivePredict();
            }
          }
          lastPrediction = prediction;
        })["catch"](removeActivePredict);
      }
    } catch (_unused) {
      removeActivePredict();
    }
  };
  editor.commands.addCommand({
    name: 'applyPredict',
    bindKey: {
      win: 'Tab',
      mac: 'Tab'
    },
    exec: function exec() {
      if (activePredict) {
        editor.setValue(activePredict.text, 1);
        editor.clearSelection();
      } else {
        editor.indent();
      }
    },
    multiSelectAction: 'forEach',
    scrollIntoView: 'selectionPart'
  });
  editor.commands.addCommand({
    name: 'forceIndent',
    bindKey: {
      win: 'Shift-Tab',
      mac: 'Shift-Tab'
    },
    exec: function exec() {
      removeActivePredict();
      editor.indent();
    },
    multiSelectAction: 'forEach',
    scrollIntoView: 'selectionPart'
  });
  editor.commands.addCommand({
    name: 'cancelPredict',
    bindKey: {
      win: 'Escape',
      mac: 'Escape'
    },
    exec: function exec() {
      removeActivePredict();
    }
  });
  var predictThrottle = -1;
  var predictOnInput = function predictOnInput() {
    if (activePredictPromise) {
      try {
        activePredictPromise.cancel();
        activePredictPromise = undefined;
      } catch (_unused2) {}
    }
    defer(function () {
      window.clearTimeout(predictThrottle);
      var editorText = editor.getValue();
      if (activePredict && (!editorText.length || activePredict.text === editorText || activePredict.text.indexOf(editorText) !== 0)) {
        removeActivePredict();
      }
      predictThrottle = window.setTimeout(updatePredictTypeahead, 300);
    });
  };
  editor.on('input', predictOnInput);
  return {
    dispose: function dispose() {
      editor.off('input', predictOnInput);
    }
  };
};