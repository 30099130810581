import { EXECUTABLE_UPDATED_TOPIC } from 'apps/editor/execution/events';
import { defineComponent, ref, toRefs, watch } from 'vue';
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import { ExecutionStatus } from "../execution/sqlExecutable";
export default defineComponent({
  name: 'ExecutableProgressBar',
  props: {
    executable: {
      type: Object,
      "default": undefined
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      executable = _toRefs.executable;
    var progress = ref(0);
    var status = ref(ExecutionStatus.ready);
    var progressBarHeight = ref('100%');
    var hideTimeout = -1;
    var updateFromExecutable = function updateFromExecutable(updated) {
      window.clearTimeout(hideTimeout);
      progress.value = updated && updated.progress || 0;
      status.value = updated && updated.status || ExecutionStatus.ready;
      if (progress.value === 100) {
        hideTimeout = window.setTimeout(function () {
          progressBarHeight.value = '0';
        }, 2000);
      } else {
        progressBarHeight.value = '100%';
      }
    };
    watch(executable, function (newVal) {
      updateFromExecutable(newVal);
    }, {
      immediate: true
    });
    subTracker.subscribe(EXECUTABLE_UPDATED_TOPIC, function (updated) {
      if (executable.value && executable.value.id === updated.id) {
        updateFromExecutable(updated);
      }
    });
    return {
      subTracker: subTracker,
      progress: progress,
      status: status,
      progressBarHeight: progressBarHeight
    };
  },
  computed: {
    visible: function visible() {
      return this.status !== ExecutionStatus.canceled;
    },
    progressBarWidth: function progressBarWidth() {
      return this.status === ExecutionStatus.failed ? '100%' : "".concat(Math.max(2, this.progress), "%");
    },
    progressClass: function progressClass() {
      if (this.status === ExecutionStatus.failed) {
        return 'progress-failed';
      }
      if (this.progress === 0 && (this.status === ExecutionStatus.running || this.status === ExecutionStatus.streaming || this.status === ExecutionStatus.starting)) {
        return 'progress-starting';
      }
      if (0 < this.progress && this.progress < 100) {
        return 'progress-running';
      }
      if (this.progress === 100) {
        return 'progress-success';
      }
      return '';
    }
  }
});