import { render } from "./EditorResizerKoBridge.vue?vue&type=template&id=4f82a0c3"
import script from "./EditorResizerKoBridge.vue?vue&type=script&lang=ts"
export * from "./EditorResizerKoBridge.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f82a0c3"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f82a0c3', script)) {
    api.reload('4f82a0c3', script)
  }
  
  module.hot.accept("./EditorResizerKoBridge.vue?vue&type=template&id=4f82a0c3", () => {
    api.rerender('4f82a0c3', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/EditorResizerKoBridge.vue"

export default script