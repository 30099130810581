import { render } from "./OptionDetailsPanel.vue?vue&type=template&id=25ba851e"
import script from "./OptionDetailsPanel.vue?vue&type=script&lang=ts"
export * from "./OptionDetailsPanel.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "25ba851e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('25ba851e', script)) {
    api.reload('25ba851e', script)
  }
  
  module.hot.accept("./OptionDetailsPanel.vue?vue&type=template&id=25ba851e", () => {
    api.rerender('25ba851e', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/aceEditor/autocomplete/details/OptionDetailsPanel.vue"

export default script