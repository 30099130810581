import { render } from "./UdfDetailsPanel.vue?vue&type=template&id=46434f2f"
import script from "./UdfDetailsPanel.vue?vue&type=script&lang=ts"
export * from "./UdfDetailsPanel.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "46434f2f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('46434f2f', script)) {
    api.reload('46434f2f', script)
  }
  
  module.hot.accept("./UdfDetailsPanel.vue?vue&type=template&id=46434f2f", () => {
    api.rerender('46434f2f', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/aceEditor/autocomplete/details/UdfDetailsPanel.vue"

export default script