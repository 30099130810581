import ExecuteButton from "./ExecuteButton.vue";
import ExecuteLimitInput from "./ExecuteLimitInput.vue";
import { defineComponent } from 'vue';
import "./ExecuableActions.scss";
export default defineComponent({
  name: 'ExecutableActions',
  components: {
    ExecuteLimitInput: ExecuteLimitInput,
    ExecuteButton: ExecuteButton
  },
  props: {
    executable: {
      type: Object,
      "default": undefined
    },
    beforeExecute: {
      type: Function,
      "default": undefined
    }
  },
  emits: ['execute-failed', 'execute-successful', 'limit-changed']
});