import { defineComponent } from 'vue';
import I18n from "../../../../../../utils/i18n";
export default defineComponent({
  name: 'OptionDetailsPanel',
  props: {
    suggestion: {
      type: Object,
      required: true
    }
  },
  computed: {
    details: function details() {
      return this.suggestion.details;
    }
  },
  methods: {
    I18n: I18n
  }
});