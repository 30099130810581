import { defineComponent } from 'vue';
import Spinner from "../../../../../../components/Spinner.vue";
import ColumnIcon from 'components/icons/vue/ColumnIcon';
import DatabaseIcon from 'components/icons/vue/DatabaseIcon';
import TableIcon from 'components/icons/vue/TableIcon';
import ViewIcon from 'components/icons/vue/ViewIcon';
import I18n from "../../../../../../utils/i18n";
var COMMENT_LOAD_DELAY = 1500;
export default defineComponent({
  name: 'CatalogEntryDetailsPanel',
  components: {
    ColumnIcon: ColumnIcon,
    TableIcon: TableIcon,
    ViewIcon: ViewIcon,
    DatabaseIcon: DatabaseIcon,
    Spinner: Spinner
  },
  props: {
    suggestion: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      loading: false,
      comment: null,
      loadTimeout: -1,
      commentPromise: null
    };
  },
  computed: {
    details: function details() {
      return this.suggestion.details;
    },
    popularityTitle: function popularityTitle() {
      return "".concat(I18n('Popularity'), " ").concat(this.suggestion.relativePopularity, "%");
    },
    showTitle: function showTitle() {
      return false;
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.details.hasResolvedComment()) {
      this.comment = this.details.getResolvedComment();
    } else {
      this.loading = true;
      this.loadTimeout = window.setTimeout(function () {
        _this.commentPromise = _this.details.getComment({
          silenceErrors: true,
          cancellable: true
        });
        _this.commentPromise.then(function (comment) {
          _this.comment = comment;
        })["finally"](function () {
          _this.loading = false;
        });
      }, COMMENT_LOAD_DELAY);
    }
  },
  unmounted: function unmounted() {
    window.clearTimeout(this.loadTimeout);
    if (this.commentPromise) {
      this.commentPromise.cancel();
    }
  },
  methods: {
    I18n: I18n
  }
});