import { defineComponent, ref, toRefs } from 'vue';
import ExecutableProgressBar from "./ExecutableProgressBar.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import { wrap } from 'vue/webComponentWrap';
var ExecutableProgressBarKoBridge = defineComponent({
  name: 'ExecutableProgressBarKoBridge',
  components: {
    ExecutableProgressBar: ExecutableProgressBar
  },
  props: {
    executableObservable: {
      type: Object,
      "default": undefined
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      executableObservable = _toRefs.executableObservable;
    var executable = ref(null);
    subTracker.trackObservable(executableObservable, executable);
    return {
      executable: executable
    };
  }
});
export var COMPONENT_NAME = 'executable-progress-bar-ko-bridge';
wrap(COMPONENT_NAME, ExecutableProgressBarKoBridge);
export default ExecutableProgressBarKoBridge;