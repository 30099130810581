// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export var EXECUTABLE_TRANSITIONED_TOPIC = 'hue.executable.status.transitioned';
export var EXECUTABLE_UPDATED_TOPIC = 'hue.executable.updated';
export var EXECUTABLE_LOGS_UPDATED_TOPIC = 'hue.executable.logs.updated';
export var EXECUTABLE_RESULT_UPDATED_TOPIC = 'hue.executable.result.updated';
export var SHOW_SESSION_AUTH_MODAL_TOPIC = 'show.session.auth.modal';