function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { defineComponent, reactive, ref, toRefs } from 'vue';
import { POST_FROM_LOCATION_WORKER_EVENT } from 'sql/workers/events';
import { wrap } from 'vue/webComponentWrap';
import PresentationMode from "./PresentationMode.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
var PresentationModeKoBridge = defineComponent({
  name: 'PresentationModeKoBridge',
  components: {
    PresentationMode: PresentationMode
  },
  props: {
    descriptionObservable: {
      type: Object,
      "default": undefined
    },
    executor: {
      type: Object,
      "default": null
    },
    initialVariables: {
      type: Object,
      "default": undefined
    },
    titleObservable: {
      type: Object,
      "default": undefined
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      descriptionObservable = _toRefs.descriptionObservable,
      titleObservable = _toRefs.titleObservable;
    var description = ref(null);
    var locations = reactive([]);
    var title = ref(null);
    subTracker.trackObservable(descriptionObservable, description);
    subTracker.trackObservable(titleObservable, title);
    subTracker.subscribe(POST_FROM_LOCATION_WORKER_EVENT, function (e) {
      if (e.data && e.data.locations) {
        locations.splice.apply(locations, [0, locations.length].concat(_toConsumableArray(e.data.locations)));
      }
    });
    return {
      description: description,
      locations: locations,
      title: title
    };
  },
  methods: {
    onBeforeExecute: function onBeforeExecute(executable) {
      this.$el.dispatchEvent(new CustomEvent('before-execute', {
        bubbles: true,
        detail: executable
      }));
    },
    onClose: function onClose() {
      this.$el.dispatchEvent(new CustomEvent('close', {
        bubbles: true
      }));
    },
    onVariablesChanged: function onVariablesChanged(variables) {
      this.$el.dispatchEvent(new CustomEvent('variables-changed', {
        bubbles: true,
        detail: variables
      }));
    }
  }
});
export var COMPONENT_NAME = 'presentation-mode-ko-bridge';
wrap(COMPONENT_NAME, PresentationModeKoBridge);
export default PresentationModeKoBridge;