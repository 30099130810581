import { render } from "./VariableSubstitutionKoBridge.vue?vue&type=template&id=824bfa7e"
import script from "./VariableSubstitutionKoBridge.vue?vue&type=script&lang=ts"
export * from "./VariableSubstitutionKoBridge.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "824bfa7e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('824bfa7e', script)) {
    api.reload('824bfa7e', script)
  }
  
  module.hot.accept("./VariableSubstitutionKoBridge.vue?vue&type=template&id=824bfa7e", () => {
    api.rerender('824bfa7e', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/variableSubstitution/VariableSubstitutionKoBridge.vue"

export default script