import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_HueButton = _resolveComponent("HueButton");
  var _component_Modal = _resolveComponent("Modal");
  return _ctx.modelValue ? (_openBlock(), _createBlock(_component_Modal, {
    key: 0,
    header: _ctx.I18n('Confirm History Clear'),
    onClose: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('update:model-value', false);
    })
  }, {
    body: _withCtx(function () {
      return [_createElementVNode("p", null, _toDisplayString(_ctx.I18n('Are you sure you want to clear the query history?')), 1 /* TEXT */)];
    }),
    footer: _withCtx(function () {
      return [_createVNode(_component_HueButton, {
        disabled: _ctx.clearingHistory,
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update:model-value', false);
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.I18n('No')), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"]), _createVNode(_component_HueButton, {
        alert: true,
        disabled: _ctx.clearingHistory,
        onClick: _ctx.clearHistory
      }, {
        "default": _withCtx(function () {
          return [!_ctx.clearingHistory ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.I18n('Yes')), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.I18n('Clearing...')), 1 /* TEXT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"])) : _createCommentVNode("v-if", true);
}