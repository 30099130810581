import { render } from "./PresentationMode.vue?vue&type=template&id=59fe80d8"
import script from "./PresentationMode.vue?vue&type=script&lang=ts"
export * from "./PresentationMode.vue?vue&type=script&lang=ts"

import "./PresentationMode.vue?vue&type=style&index=0&id=59fe80d8&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "59fe80d8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('59fe80d8', script)) {
    api.reload('59fe80d8', script)
  }
  
  module.hot.accept("./PresentationMode.vue?vue&type=template&id=59fe80d8", () => {
    api.rerender('59fe80d8', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/presentationMode/PresentationMode.vue"

export default script