import { defineComponent } from 'vue';
import { HIDE_FIXED_HEADERS_EVENT, REDRAW_FIXED_HEADERS_EVENT } from "../events";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import huePubSub from 'utils/huePubSub';
var CUSTOM_HEIGHT_STORAGE_KEY = 'ace.editor.custom.height';
var TARGET_ELEMENT_SELECTOR = '.ace-editor-component';
var CONTENT_PANEL_SELECTOR = '.content-panel';
export default defineComponent({
  name: 'EditorResizer',
  props: {
    editor: {
      type: Object,
      required: false,
      "default": null
    }
  },
  setup: function setup() {
    return {
      subTracker: new SubscriptionTracker()
    };
  },
  data: function data(thisComp) {
    return {
      newHeight: 0,
      startHeight: 0,
      startY: 0,
      targetElement: null,
      onMouseMove: thisComp.drag.bind(this),
      onMouseUp: thisComp.stop.bind(this)
    };
  },
  unmounted: function unmounted() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.subTracker.dispose();
  },
  methods: {
    drag: function drag(event) {
      if (!this.targetElement || !this.editor) {
        return;
      }
      var diff = event.clientY - this.startY;
      this.newHeight = Math.max(this.startHeight + diff, 128);
      this.targetElement.style.height = this.newHeight + 'px';
      this.editor.resize();
    },
    findTarget: function findTarget() {
      var contentPanel = this.$refs.root.closest(CONTENT_PANEL_SELECTOR);
      if (contentPanel) {
        this.targetElement = contentPanel.querySelector(TARGET_ELEMENT_SELECTOR);
      }
      if (!this.targetElement) {
        console.warn("EditorResizer could not find the target element '".concat(TARGET_ELEMENT_SELECTOR, "'."));
        return;
      }
    },
    start: function start(event) {
      if (!this.targetElement) {
        this.findTarget();
        if (!this.targetElement) {
          return;
        }
      }
      this.startY = event.clientY;
      this.startHeight = this.targetElement.offsetHeight;
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
      huePubSub.publish(HIDE_FIXED_HEADERS_EVENT);
    },
    stop: function stop() {
      huePubSub.publish(REDRAW_FIXED_HEADERS_EVENT);
      document.dispatchEvent(new Event('editorSizeChanged')); // TODO: Who listens?
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
      localStorage.setItem(CUSTOM_HEIGHT_STORAGE_KEY, String(this.newHeight));
    }
  }
});