import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
_pushScopeId("data-v-f5f66efe");
var _hoisted_1 = {
  "class": "executable-progress-container"
};
var _hoisted_2 = {
  key: 0,
  "class": "executable-progress"
};
_popScopeId();
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.visible ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    "class": _normalizeClass(["executable-progress-bar", _ctx.progressClass]),
    style: _normalizeStyle({
      width: _ctx.progressBarWidth,
      height: _ctx.progressBarHeight
    })
  }, null, 6 /* CLASS, STYLE */)])) : _createCommentVNode("v-if", true)]);
}