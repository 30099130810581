import { defineComponent } from 'vue';
import SqlText from "../../../../../../components/SqlText.vue";
import I18n from "../../../../../../utils/i18n";
export default defineComponent({
  name: 'PopularAggregateUdfPanel',
  components: {
    SqlText: SqlText
  },
  props: {
    suggestion: {
      type: Object,
      required: true
    },
    connector: {
      type: Object,
      "default": undefined
    }
  },
  computed: {
    details: function details() {
      return this.suggestion.details;
    },
    description: function description() {
      return this.details["function"] && this.details["function"].description || '';
    },
    dialect: function dialect() {
      return this.connector && this.connector.dialect;
    },
    popularityTitle: function popularityTitle() {
      return "".concat(I18n('Relative popularity'), ": ").concat(this.details.relativePopularity || '?', "%");
    }
  },
  methods: {
    I18n: I18n
  }
});