import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "snippet-execute-actions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ExecuteButton = _resolveComponent("ExecuteButton");
  var _component_ExecuteLimitInput = _resolveComponent("ExecuteLimitInput");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ExecuteButton, {
    executable: _ctx.executable,
    "before-execute": _ctx.beforeExecute,
    onExecuteSuccessful: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('execute-successful', $event);
    }),
    onExecuteFailed: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('execute-failed', $event);
    })
  }, null, 8 /* PROPS */, ["executable", "before-execute"]), _createVNode(_component_ExecuteLimitInput, {
    executable: _ctx.executable,
    onLimitChanged: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('limit-changed', $event);
    })
  }, null, 8 /* PROPS */, ["executable"])]);
}