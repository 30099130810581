import { render } from "./ResultTableKoBridge.vue?vue&type=template&id=0c91a312"
import script from "./ResultTableKoBridge.vue?vue&type=script&lang=ts"
export * from "./ResultTableKoBridge.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0c91a312"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0c91a312', script)) {
    api.reload('0c91a312', script)
  }
  
  module.hot.accept("./ResultTableKoBridge.vue?vue&type=template&id=0c91a312", () => {
    api.rerender('0c91a312', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/result/ResultTableKoBridge.vue"

export default script