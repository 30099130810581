import { render } from "./EditorResizer.vue?vue&type=template&id=0a9d7716&scoped=true"
import script from "./EditorResizer.vue?vue&type=script&lang=ts"
export * from "./EditorResizer.vue?vue&type=script&lang=ts"

import "./EditorResizer.vue?vue&type=style&index=0&id=0a9d7716&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0a9d7716"
/* hot reload */
if (module.hot) {
  script.__hmrId = "0a9d7716"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0a9d7716', script)) {
    api.reload('0a9d7716', script)
  }
  
  module.hot.accept("./EditorResizer.vue?vue&type=template&id=0a9d7716&scoped=true", () => {
    api.rerender('0a9d7716', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/EditorResizer.vue"

export default script