import { defineComponent, ref, toRefs } from 'vue';
import { wrap } from 'vue/webComponentWrap';
import AceEditor from "./AceEditor.vue";
import sqlAnalyzerRepository from 'catalog/analyzer/sqlAnalyzerRepository';
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import sqlParserRepository from 'parse/sql/sqlParserRepository';
import sqlReferenceRepository from 'sql/reference/sqlReferenceRepository';
var AceEditorKoBridge = defineComponent({
  name: 'AceEditorKoBridge',
  components: {
    AceEditor: AceEditor
  },
  props: {
    executor: {
      type: Object,
      "default": undefined
    },
    idObservable: {
      type: Object,
      "default": undefined
    },
    valueObservable: {
      type: Object,
      "default": undefined
    },
    cursorPositionObservable: {
      type: Object,
      "default": undefined
    },
    aceOptions: {
      type: Object,
      "default": undefined
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      cursorPositionObservable = _toRefs.cursorPositionObservable,
      idObservable = _toRefs.idObservable,
      valueObservable = _toRefs.valueObservable;
    var cursorPosition = ref(null);
    var editorId = ref(null);
    var value = ref(null);
    subTracker.trackObservable(idObservable, editorId);
    subTracker.trackObservable(cursorPositionObservable, cursorPosition);
    subTracker.trackObservable(valueObservable, value);
    return {
      cursorPosition: cursorPosition,
      editorId: editorId,
      sqlAnalyzerProvider: sqlAnalyzerRepository,
      sqlParserProvider: sqlParserRepository,
      sqlReferenceProvider: sqlReferenceRepository,
      value: value
    };
  },
  methods: {
    aceCreated: function aceCreated(editor) {
      this.$el.dispatchEvent(new CustomEvent('ace-created', {
        bubbles: true,
        detail: editor
      }));
    },
    createNewDoc: function createNewDoc() {
      this.$el.dispatchEvent(new CustomEvent('create-new-doc', {
        bubbles: true
      }));
    },
    cursorChanged: function cursorChanged(cursorPosition) {
      this.$el.dispatchEvent(new CustomEvent('cursor-changed', {
        bubbles: true,
        detail: cursorPosition
      }));
    },
    saveDoc: function saveDoc() {
      this.$el.dispatchEvent(new CustomEvent('save-doc', {
        bubbles: true
      }));
    },
    togglePresentationMode: function togglePresentationMode() {
      this.$el.dispatchEvent(new CustomEvent('toggle-presentation-mode', {
        bubbles: true
      }));
    },
    valueChanged: function valueChanged(value) {
      this.$el.dispatchEvent(new CustomEvent('value-changed', {
        bubbles: true,
        detail: value
      }));
    }
  }
});
export var COMPONENT_NAME = 'ace-editor-ko-bridge';
wrap(COMPONENT_NAME, AceEditorKoBridge);
export default AceEditorKoBridge;