import { render } from "./AceSyntaxDropdown.vue?vue&type=template&id=325d60bd"
import script from "./AceSyntaxDropdown.vue?vue&type=script&lang=ts"
export * from "./AceSyntaxDropdown.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "325d60bd"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('325d60bd', script)) {
    api.reload('325d60bd', script)
  }
  
  module.hot.accept("./AceSyntaxDropdown.vue?vue&type=template&id=325d60bd", () => {
    api.rerender('325d60bd', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/aceEditor/AceSyntaxDropdown.vue"

export default script