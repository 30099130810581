import { defineComponent, ref } from 'vue';
import VariableSubstitution from "./VariableSubstitution.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import { POST_FROM_LOCATION_WORKER_EVENT } from 'sql/workers/events';
import { wrap } from 'vue/webComponentWrap';
var VariableSubstitutionKoBridge = defineComponent({
  name: 'VariableSubstitutionKoBridge',
  components: {
    VariableSubstitution: VariableSubstitution
  },
  props: {
    initialVariables: {
      type: Object,
      "default": undefined
    }
  },
  setup: function setup() {
    var subTracker = new SubscriptionTracker();
    var locations = ref([]);
    subTracker.subscribe(POST_FROM_LOCATION_WORKER_EVENT, function (e) {
      if (e.data && e.data.locations) {
        locations.value = e.data.locations;
      }
    });
    return {
      locations: locations
    };
  },
  methods: {
    onVariablesChanged: function onVariablesChanged(variables) {
      this.$el.dispatchEvent(new CustomEvent('variables-changed', {
        bubbles: true,
        detail: variables
      }));
    }
  }
});
export var COMPONENT_NAME = 'variable-substitution-ko-bridge';
wrap(COMPONENT_NAME, VariableSubstitutionKoBridge);
export default VariableSubstitutionKoBridge;