import { render } from "./AceEditor.vue?vue&type=template&id=5b050d96"
import script from "./AceEditor.vue?vue&type=script&lang=ts"
export * from "./AceEditor.vue?vue&type=script&lang=ts"

import "./AceEditor.vue?vue&type=style&index=0&id=5b050d96&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5b050d96"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5b050d96', script)) {
    api.reload('5b050d96', script)
  }
  
  module.hot.accept("./AceEditor.vue?vue&type=template&id=5b050d96", () => {
    api.rerender('5b050d96', render)
  })

}

script.__file = "desktop/core/src/desktop/js/apps/editor/components/aceEditor/AceEditor.vue"

export default script